import { CSSProperties, forwardRef, useState } from "react";
import cn from "clsx";
import * as Dialog from "@radix-ui/react-dialog";
import { KALTURA_PARTNER_ID } from "design-system/utils/constants";
import {
  KalturaEmbed,
  KalturaEmbedProps,
} from "design-system/components/primitives/kaltura-embed/kaltura-embed";

export interface MediaAssetKalturaModalProps {
  kaltura: KalturaEmbedProps;
  className?: string;
  aspectRatio?: `${string}/${string}`;
  hide?: {
    caption?: boolean;
    focusables?: boolean;
  };
}

export const MediaAssetKalturaModal = forwardRef<
  HTMLButtonElement,
  MediaAssetKalturaModalProps
>(({ kaltura, className, aspectRatio, hide }, ref) => {
  const [open, setOpen] = useState(false);

  // https://developer.kaltura.com/api-docs/Engage_and_Publish/kaltura-thumbnail-api.html
  const poster = `https://cdnsecakmi.kaltura.com/p/${KALTURA_PARTNER_ID}/thumbnail/entry_id/${kaltura.entryId}/width/2000/type/1/quality/100`;
  const imgStyle = aspectRatio
    ? ({
        aspectRatio: aspectRatio,
        objectFit: "cover",
        objectPosition: "center center",
      } as CSSProperties)
    : {};
  const ariaLabel = kaltura.title
    ? `Play ${kaltura.title} video`
    : `Play video content`;

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <div className={cn(`hbs-media-asset__modal-trigger-wrapper`, className)}>
        {hide?.focusables ? (
          <div className="hbs-media-asset__modal-trigger">
            {/* eslint-disable-next-line react/forbid-elements */}
            <img src={poster} alt="" loading="lazy" style={imgStyle} />
            <span className="hbs-global-visually-hidden">{ariaLabel}</span>
          </div>
        ) : (
          <Dialog.Trigger ref={ref} className="hbs-media-asset__modal-trigger">
            {/* eslint-disable-next-line react/forbid-elements */}
            <img src={poster} alt="" loading="lazy" style={imgStyle} />
            <span className="hbs-global-visually-hidden">{ariaLabel}</span>
          </Dialog.Trigger>
        )}

        <div className="hbs-media-asset__kaltura-text">
          <span
            className="hbs-media-asset__kaltura-text-prompt"
            aria-hidden="true"
          >
            Play
          </span>{" "}
          {kaltura.duration && (
            <span className="hbs-media-asset__kaltura-duration">
              <span className="hbs-global-visually-hidden">
                Video duration:
              </span>
              {kaltura.duration}
            </span>
          )}
        </div>
      </div>

      <Dialog.Portal>
        <Dialog.Overlay className="hbs-media-asset__modal-overlay">
          <Dialog.Content className="hbs-media-asset__modal-content">
            <div className="hbs-media-asset__modal-content-inner">
              <Dialog.Close
                className="hbs-media-asset__modal-content-close"
                aria-label="Close popup"
              >
                &#x2715;
              </Dialog.Close>
              <KalturaEmbed {...kaltura} />
            </div>
          </Dialog.Content>
        </Dialog.Overlay>
      </Dialog.Portal>
    </Dialog.Root>
  );
});
