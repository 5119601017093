import "./kaltura-embed.scss";

import "./kaltura-types";

import cn from "clsx";
import { ReactNode, useEffect, useRef, useState, useId } from "react";
import { loadScript } from "design-system/hooks/use-script";
import { createThreePlayScript } from "./three-play-script";
import {
  KALTURA_PARTNER_ID,
  KALTURA_SCRIPT_SRC,
  KALTURA_UI_CONF_ID,
  BREAKPOINTS,
} from "design-system/utils/constants";
import { setKalturaVideoEvents } from "./kaltura-video-events";
import { useDataLayer } from "frontend/hooks/use-data-layer";

const { TABLET } = BREAKPOINTS;

export interface KalturaEmbedProps {
  entryId: string;
  title?: string;
  withModal?: boolean;
  className?: string;
  duration?: string;
  caption?: ReactNode;
  description?: ReactNode;
  threePlayId?: string;
  threePlayProjectId?: string;
  noModalMobileOverride?: boolean;
  hide?: {
    caption?: boolean;
    focusables?: boolean;
  };
}

/**
 * Component for embedding Kaltura video player widgets. Used within the `MediaAsset` primitive.
 * See the docs for more details: http://player.kaltura.com/docs/api
 *
 * ## See it in use on...
 * - The [article kitchen sink page](/story/example-pages-article-pages-kitchen-sink--story)
 *
 * - **`id: P-016-000-00`**
 */
export function KalturaEmbed({
  hide,
  caption,
  description,
  duration,
  entryId,
  title,
  withModal,
  className,
  threePlayId,
  threePlayProjectId,
  noModalMobileOverride,
}: KalturaEmbedProps) {
  // We need unique ids because the carousel creates duplicates to animate between slides.
  // Replace colons with dashes to avoid invalid HTML IDs.
  const uniqueId = useId().replace(/:/g, "");
  const buttonTextRef = useRef<HTMLDivElement>(null);
  const [play, setPlay] = useState(withModal ?? false);
  const { pushDataLayer } = useDataLayer();
  const withModalRef = useRef<boolean>(play);

  const videoTargetId = `kaltura-player-${entryId}-${uniqueId}`;
  const threePlayTargetId = `threePlay-${entryId}`;

  const hasThreePlay = Boolean(threePlayId && threePlayProjectId);
  const ariaLabel = title ? `Play ${title} video` : `Play video content`;

  useEffect(() => {
    if (play) {
      if (noModalMobileOverride && window.innerWidth < TABLET) {
        withModalRef.current = false;
        setPlay(false);
      } else {
        withModalRef.current = withModal ?? false;
      }
      loadScript(KALTURA_SCRIPT_SRC, {
        onload: () => {
          buttonTextRef.current?.classList.add("hbs-global-visually-hidden");

          if (window.kWidget) {
            window.kWidget.embed({
              entry_id: entryId,
              targetId: videoTargetId,
              wid: `_${KALTURA_PARTNER_ID}`,
              uiconf_id: KALTURA_UI_CONF_ID,
              flashvars: { autoPlay: true },
              readyCallback: (videoTargetId) => {
                setKalturaVideoEvents(videoTargetId, pushDataLayer);

                if (threePlayId && threePlayProjectId) {
                  const threePlayScriptSrc = createThreePlayScript({
                    threePlayId,
                    threePlayProjectId,
                    videoTargetId,
                    threePlayTargetId,
                  });
                  loadScript(threePlayScriptSrc, { allowDuplicates: true });
                }
              },
            });
          }
        },
      });
    }
  }, [
    play,
    entryId,
    videoTargetId,
    threePlayId,
    threePlayProjectId,
    threePlayTargetId,
    pushDataLayer,
  ]);

  return (
    <figure className={cn("hbs-media-asset", className)}>
      <div className={"hbs-kaltura-embed"} data-chromatic="ignore">
        <div className="hbs-kaltura-embed__responsive-spacer" />
        <div id={videoTargetId} className="hbs-kaltura-embed__player">
          <div style={{ position: "relative", width: "100%", height: "100%" }}>
            <button
              onClick={() => play !== true && setPlay(true)}
              aria-label={ariaLabel}
              className="kWidgetCentered kWidgetPlayBtn"
              id={`${videoTargetId}_playBtn`}
            ></button>
            {/* eslint-disable-next-line react/forbid-elements */}
            <img
              loading="lazy"
              alt=""
              className="kWidgetCentered"
              src={`https://cdnsecakmi.kaltura.com/p/${KALTURA_PARTNER_ID}/thumbnail/entry_id/${entryId}/width/${650}/type/1`}
            />
          </div>
        </div>

        {!withModalRef.current && (
          <div className="hbs-media-asset__kaltura-text" ref={buttonTextRef}>
            <span
              className="hbs-media-asset__kaltura-text-prompt"
              aria-hidden="true"
            >
              Play
            </span>{" "}
            {duration && (
              <span className="hbs-media-asset__kaltura-duration">
                <span className="hbs-global-visually-hidden">
                  Video duration:
                </span>
                {duration}
              </span>
            )}
          </div>
        )}
      </div>

      {hasThreePlay && (
        <div id={threePlayTargetId} className="hbs-threeplay-embed" />
      )}

      {description && (
        <div className="hbs-global-visually-hidden">{description}</div>
      )}

      {!hide?.caption && caption && (
        <figcaption>
          <span className="hbs-media-asset__caption-text">
            <div>{caption}</div>
          </span>
        </figcaption>
      )}
    </figure>
  );
}
